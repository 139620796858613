/*css for charts */
.container-flexbox,
.cust-container-flexbox {
  display: flex;
  flex-direction: column;
  background-color: rgba(211, 212, 218, 0.2);
  border-radius: 10px;
  width: 96%;
  margin: 2% auto;
  padding: 25px;
}

.user-controls-container-flexbox {
  display: flex;
  flex-direction: column;
  background-color: rgba(211, 212, 218, 0.2);
  border-radius: 10px;
  width: 94%;
  margin: 2% auto;
  padding: 25px 25px 0px 25px;
}

.tab-content-flexbox {
  display: flex;
  flex-direction: column;
  background-color: rgba(211, 212, 218, 0.2);
  border-radius: 10px;
  padding: 25px;
}

.cust-container-flexbox {
  margin-top: 5%;
  flex-direction: column;
  gap: 15px;
}


.chart-container,
.agechart-container,
.data-container,
.bar-chart,
.linegraph-container,
.device-linegraph,
.cust-linechart,
.horizontal-barchart,
.map-data {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.number-flexbox,
.chart-flexbox {
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.number-flexbox,
.chart-flexbox {
  margin-bottom: 2%;
}

.data-container {
  width: 32%;
  flex-direction: column;
  padding: 0 0 10px 0;
}

.number-value,
.total-headers {
  font-size: 8.5vw;
  text-align: center;
  margin: 0;
}

.download-sidetext {
  font-weight: bold;
  color: #a7a6a6;
  font-size: 1vw;
  text-align: center;
    align-items: center;
}

.small-number-value {
  font-size: 4.0vw;
  text-align: center;
  margin: 0;
}

.total-headers {
  font-weight: bold;
  color: #a7a6a6;
  font-size: 1vw;
}

.chart-flexbox div,
.linegraph-container {
  width: 30%;
  padding: 0 1%;
  display: flex;
  align-items: center;
}

.bar-chart {
  width: 96%;
  height: 450px;
  margin: auto;
  margin-bottom: 1%;
  padding: 30px;
}

#horizontal-barchart-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.horizontal-barchart {
  flex-grow: 1;
  padding: 15px;
  min-height: 400px;
}

.horizontal-block {
  width: 49.5%;
}

.map-data {
  margin-top: 1%;
  display: flex;
  padding: 15px;
}

.map {
  width: 60%;
}

.customer-list {
  width: 40%;
  text-align: left;
}

.customer-list h3 {
  margin: 10px 0 0 38px;
  font-size: 45px;
}

.customer-list ul {
  list-style-type: none;
}

.customer-list li {
  margin-bottom: 8px;
  font-size: 1.2vw;
}

/* Customer page chart css */
.cust-barchart-container,
.cust-linechart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  flex-grow: 1;
  gap: 15px;
}
.cust-barchart-container-row {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  flex-grow: 1;
  gap: 10px;
}
.cust-barchart-logo {
  width: 50%;
}

.cust-linechart-container img {
  width: 80%;
  margin: 3% auto auto auto;
}


#custDevices {
  margin: auto;
  width: 100%;
}

.timeframe-buttons {
  display: flex;
  gap: 2px;
}

.timeframe-buttons button {
  background: transparent;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 8px 10px;
  user-select: none;
}

.timeframe-buttons button:hover {
  background: #f1f1f1;
}

.timeframe-buttons button.selected {
  background: #007bff;
  color: #fff;
}
